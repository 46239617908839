<script setup>
const props = defineProps({
  modelValue: { type: [String, Number], default: '' },
  type: { type: String, default: 'text' },
  placeholder: { type: String, default: '' },
  label: { type: String, default: '' },
  autocomplete: { type: String, default: '' },
  disabled: Boolean,
  required: Boolean,
  noSpellcheck: Boolean,
  lazy: Boolean,
  autofocus: Boolean,
  desc: { type: String, default: '' },
  error: { type: String, default: '' },
})

const { modelValue, label, error } = toRefs(props)
const inputEl = ref()

const emit = defineEmits([
  'update:modelValue',
  'change',
  'focus',
  'blur',
  'esc',
  'enter',
  'keyup',
  'keydown',
  'arrow-down',
  'arrow-up'])

const clearErrorDelay = 1000
let clearErrorTimeout = null

const showError = ref(false)
const internalError = ref('')
const internalValue = ref('')

onMounted(() => {
  internalValue.value = modelValue.value
  if (props.autofocus) {
    focus()
  }
})
watch(modelValue, (val) => {
  internalValue.value = val
})
watch(error, (val) => {
  showError.value = !!val
})
watch(internalError, (val) => {
  showError.value = !!val
})

const model = computed({
  get: () => {
    return internalValue.value || modelValue.value
  },
  set: (val) => {
    internalValue.value = val
    if (!props.lazy) {
      emit('update:modelValue', val)
    }
  },
})

const placeholderText = computed(() => {
  return props.placeholder || label.value || ''
})

const errorText = computed(() => {
  if (!showError.value) {
    return ''
  }
  return error.value || internalError.value
})

const descText = computed(() => {
  return errorText.value || props.desc || ''
})

function onFocus() {
  clearErrorTimeout = window.setTimeout(() => {
    showError.value = ''
  }, clearErrorDelay)
}

function onBlur() {
  window.clearTimeout(clearErrorTimeout)
  validate()
  emit('blur')
}

function onEsc() {
  window.clearTimeout(clearErrorTimeout)
  validate()
  emit('esc')
}

function onChange() {
  validate()
  if (props.lazy) {
    emit('update:modelValue', internalValue.value)
    internalValue.value = ''
  }
  emit('change', internalValue.value)
}
function onEnter() {
  onChange()
  emit('enter')
}

function validate() {
  if (props.required && !internalValue.value) {
    internalError.value = 'Required'
    showError.value = true
  }
  else {
    internalError.value = ''
    showError.value = false
  }
}
function focus() {
  inputEl.value?.focus()
}
defineExpose({
  focus,
})
</script>

<template>
  <label class="input-box" :class="{ error }">
    <div v-if="label" class="label">
      {{ label }}
    </div>
    <span class="input-wrap" :data-value="model">
      <slot>
        <input
          ref="inputEl"
          v-model="model"
          :type="props.type"
          :placeholder="placeholderText"
          :autocomplete="props.autocomplete"
          :disabled="props.disabled"
          :spellcheck="!props.noSpellcheck"
          :autofocus="props.autofocus"
          @focus="onFocus"
          @blur="onBlur"
          @change="onChange"
          @keydown.enter.prevent="onEnter"
          @keydown.esc="onEsc"
          @keyup.exact="emit('keyup', $event)"
          @keydown="emit('keydown', $event)"
          @keydown.down.prevent="emit('arrow-down')"
          @keydown.up.prevent="emit('arrow-up')">
      </slot>
      <slot name="add-on" />
    </span>
    <UiCollapsibleBox :open="!!descText" class="desc" :class="{ open: !!descText, error: showError }">
      <span>{{ descText }}</span>
    </UiCollapsibleBox>
  </label>
</template>

<style lang="scss">
$padding-micro: var(--padding-mini, 0.25rem);
$padding-big: var(--padding-big, 1.5rem);
$label-font-size: var(--font-size-caption, 0.85rem);
$label-color: var(--color-label);
$error-color: var(--color-error);
$focus-color: var(--color-focus, var(--color-accent));

.input-box {
  display: block;

  &.open .desc {
    margin-top: var(--padding-mini);
    text-wrap: balance;
  }

  &.lg input {
    font-size: var(--h2);
    height: var(--h2);
  }

  &.centered input {
    // text-align: center;
  }
}

.label {
  color: var(--color-label);
  margin-bottom: var(--padding-mini);
  padding-inline: var(--padding-base);
  font-size: var(--font-size-sm);
}

.input-wrap {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  width: fit-content;
  border-radius: var(--border-radius-base);
  background-color: var(--color-input-bg);

  &::after,
  input,
  textarea {
    width: 100%;
    min-width: 1em;
    grid-area: 1 / 1;
    font: inherit;
    margin: 0;
    resize: none;
    background: none;
    appearance: none;
    border: none;
    font-family: var(--font-family-main);
    font-size: var(--font-size-body);
  }

  input:not([type=range]) {
    padding: 0 var(--padding-base);
  }

  &::after {
    content: attr(data-value) ' ';
    visibility: hidden;
    white-space: pre-wrap;
  }

  &:focus-within {
    box-shadow: 0 0 0 3px var(--color-bg);
  }
}

input {
  height: var(--input-size, var(--button-height, 2.25rem));
  text-overflow: ellipsis;
  font-family: var(--font-family-main);
  max-width: 100%;

  &.error {
    color: $error-color;
    border-color: $error-color;

    &::placeholder {
      color: var(--color-error, #{#b9888e});
    }
  }

  &.centered {
    text-align: center;
  }
}
</style>
